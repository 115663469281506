import * as React from "react"
import type {HeadFC} from "gatsby"
import Layout from "../components/Layout";
import Headline from "../components/Headline";
import SEO from "../components/seo";

export default () => {
    return (
        <Layout>
            <div className="container px-7 5">
                <article className={'mt-18'}>
                    <Headline title={'Datenschutzerklärung'}></Headline>
                    <div className="text"><p>Mit dieser Datenschutzerklärung möchten wir Sie über Art, Umfang und Zweck
                        der
                        Verarbeitung von personenbezogenen Daten (im Folgenden auch nur als "Daten" bezeichnet)
                        aufklären.
                        Personenbezogene Daten sind alle Daten, die einen persönlichen Bezug zu Ihnen aufweisen, z. B.
                        Name,
                        Adresse, E-Mail-Adresse oder Ihr Nutzerverhalten. Die Datenschutzerklärung gilt für alle von uns
                        vorgenommene Daten-Verarbeitungsvorgänge sowohl im Rahmen unserer Kerntätigkeit als auch für die
                        von
                        uns vorgehaltenen Online-Medien.</p></div>
                </article>

                <article className="mt-18">
                    <Headline title={'Verantwortlicher'}></Headline>
                    <p>
                        L. Schuler GmbH &amp; Co. KG<br/>
                        Johann-Sebastian-Bach-Straße 8<br/>
                        89264 Weißenhorn<br/>
                        Deutschland<br/>
                        <a href={'tel:049730996180'}>049 7309 9618 0</a><br/>
                        <a href={'mailto:info@schuler-mb.de'}>info@schuler-mb.de</a><br/>
                        <a href={'https://www.schuler-mb.de/impressum'}>https://www.schuler-mb.de/impressum</a>
                    </p>
                </article>

                <article className={'mt-18'}>
                    <Headline title={'Verarbeitung'}></Headline>
                    <h2>
                        Verarbeitung Ihrer Daten im Rahmen der von uns erbrachten handwerklichen
                        Leistungen
                    </h2>
                    <p>
                        Sofern Sie unser Kunde oder Geschäftspartner sind oder sich für die Leistungen unseres
                        Handwerksbetriebes interessieren, richtet sich Art, Umfang und Zweck der Verarbeitung Ihrer
                        Daten nach dem zwischen uns bestehenden vertraglichen bzw. vorvertraglichen Beziehungen. In
                        diesem Sinne gehören zu den von uns verarbeiteten Daten all diejenigen Daten, die wir von Ihnen
                        abfragen oder die Sie uns bereitstellen, um Ihre Anfrage zu beantworten, Ihnen ein Angebot zu
                        erstellen oder Ihre Bestellung zu bearbeiten. Sofern sich aus den weiteren Hinweisen dieser
                        Datenschutzerklärung nichts anderes ergibt, beschränkt sich die Verarbeitung Ihrer Daten sowie
                        deren Weitergabe an Dritte auf diejenigen Daten, die zur Beantwortung Ihrer Anfragen und/oder
                        zur Erfüllung des zwischen Ihnen und uns geschlossenen Vertrages, zur Wahrung unserer Rechte
                        sowie zur Erfüllung gesetzlicher Pflichten erforderlich und zweckmäßig sind. Welche Daten
                        hierfür erforderlich sind, teilen wir Ihnen vor oder im Rahmen der Datenerhebung mit. Soweit wir
                        zur Erbringung unserer Leistungen Drittanbieter einsetzen, gelten die Datenschutzhinweise der
                        jeweiligen Drittanbieter.
                    </p>
                    <p><strong>Betroffene Daten:</strong></p>
                    <ul>
                        <li>Bestandsdaten (bspw. Namen, Adressen)</li>
                        <li>Zahlungsdaten (bspw. Bankverbindungsdaten, Rechnungen)</li>
                        <li>Kontakdaten (bspw. E-Mail-Adresse, Telefonnummer, Postanschrift)</li>
                        <li>Vertragsdaten (bspw. Vertragsgegenstand, Vertragsdauer)</li>
                    </ul>
                    <p><strong>Betroffene Personen: </strong>Interessenten, Geschäfts- und Vertragspartner</p><p>
                    <strong>Verarbeitungszweck: </strong>Abwicklung vertraglicher Leistungen, Kommunikation
                    sowie
                    Beantwortung von Kontaktanfragen, Büro und Organisationsverfahren</p><p>
                    <strong>Rechtsgrundlage:</strong> Vertragserfüllung und vorvertragliche Anfragen, Art. 6
                    Abs. 1
                    lit. b DSGVO, rechtliche Verpflichtung, Art. 6 Abs. 1 lit. c DSGVO, berechtigtes Interesse,
                    Art.
                    6 Abs. 1 lit. f DSGVO</p>
                </article>

                <article className={'mt-18'}>
                    <Headline title={'Rechte'}></Headline>
                    <p>
                        Nach der DSGVO stehen Ihnen die nachfolgend aufgeführten Rechte zu, die Sie jederzeit bei dem in
                        Ziffer 1. dieser Datenschutzerklärung genannten Verantwortlichen geltend machen können:
                    </p>
                    <ul>
                        <li><strong>Recht auf Auskunft:</strong> Sie haben das Recht, von uns Auskunft darüber zu
                            verlangen, ob und welche Daten wir von Ihnen verarbeiten.
                        </li>
                        <li><strong>Recht auf Berichtigung:</strong> Sie haben das Recht, die Berichtigung unrichtiger
                            oder Vervollständigung unvollständiger Daten zu verlangen.
                        </li>
                        <li><strong>Recht auf Löschung:</strong> Sie haben das Recht, die Löschung Ihrer Daten zu
                            verlangen.
                        </li>
                        <li><strong>Recht auf Einschränkung:</strong> Sie haben in bestimmten Fällen das Recht zu
                            verlangen, dass wir Ihre Daten nur noch eingeschränkt bearbeiten.
                        </li>
                        <li><strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht zu verlangen, dass wir
                            Ihnen oder einem anderen Verantwortlichen Ihre Daten in einem strukturierten, gängigen und
                            maschinenlesebaren Format übermitteln.
                        </li>
                        <li><strong>Beschwerderecht</strong>: Sie haben das Recht, sich bei einer Aufsichtsbehörde zu
                            beschweren. Zuständig ist die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes, Ihres
                            Arbeitsplatzes oder unseres Firmensitzes.
                        </li>
                    </ul>

                    <h3>Widerrufsrecht</h3>
                    <p>
                        Sie haben das Recht, die von Ihnen erteilte Einwilligung zur Datenverarbeitung jederzeit zu
                        widerrufen.
                    </p>
                    <h3>Widerspruchsrecht</h3>
                    <p>
                        Sie haben das Recht, jederzeit gegen die Verarbeitung Ihrer Daten, die wir auf unser
                        berechtigtes Interesse nach Art. 6 Abs. 1 lit. f DSGVO stützen, Widerspruch einzulegen. Sofern
                        Sie von Ihrem Widerspruchsrecht Gebrauch machen, bitten wir Sie um die Darlegung der Gründe. Wir
                        werden Ihre personenbezogenen Daten dann nicht mehr verarbeiten, es sei denn, wir können Ihnen
                        gegenüber nachweisen, dass zwingende schutzwürdige Gründe an der Datenverarbeitung Ihre
                        Interessen und Rechte überwiegen.
                    </p>
                    <p>
                        <strong>
                            Unabhängig vom vorstehend Gesagten, haben Sie das jederzeitige Recht, der Verarbeitung Ihrer
                            personenbezogenen Daten für Zwecke der Werbung und Datenanalyse zu widersprechen.
                        </strong>
                    </p>
                    <p>
                        Ihren Widerspruch richten Sie bitte an die oben angegebene Kontaktadresse des
                        Verantwortlichen.
                    </p>
                    <h2>Wann löschen wir Ihre Daten?</h2>
                    <p>
                        Wir löschen Ihre Daten dann, wenn wir diese nicht mehr brauchen oder Sie uns dies vorgeben. Das
                        bedeutet, dass - sofern sich aus den einzelnen Datenschutzhinweisen dieser Datenschutzerklärung
                        nichts anderes ergibt - wir Ihre Daten löschen,
                    </p>
                    <ul>
                        <li>
                            wenn der Zweck der Datenverarbeitung weggefallen ist und damit die jeweilige in den
                            einzelnen Datenschutzhinweisen genannte Rechtsgrundlage nicht mehr besteht, also bspw.
                            <ul>
                                <li>nach Beendigung der zwischen uns bestehenden vertraglichen oder mitgliedschaftlichen
                                    Beziehungen (Art. 6 Abs. 1 lit. a DSGVO) oder
                                </li>
                                <li>nach Wegfall unseres berechtigten Interesses an der weiteren Verarbeitung oder
                                    Speicherung Ihrer Daten (Art. 6 Abs. 1 lit. f DSGVO),
                                </li>
                            </ul>
                        </li>
                        <li>
                            wenn Sie von Ihrem Widerrufsrecht Gebrauch machen und keine anderweitige gesetzliche
                            Rechtsgrundlage für die Verarbeitung im Sinne von Art. 6 Abs. 1 lit. b-f DSGVO eingreift,
                        </li>
                        <li>
                            wenn Sie vom Ihrem Widerspruchsrecht Gebrauch machen und der Löschung keine zwingenden
                            schutzwürdigen Gründe entgegenstehen.
                        </li>
                    </ul>
                    <p>
                        Sofern wir (bestimmte Teile) Ihre(r) Daten jedoch noch für andere Zwecke vorhalten müssen, weil
                        dies etwa steuerliche Aufbewahrungsfristen (in der Regel 6 Jahre für Geschäftskorrespondenz bzw.
                        10 Jahre für Buchungsbelege) oder die Geltendmachung, Ausübung oder Verteidigung von
                        Rechtsansprüchen aus vertraglichen Beziehungen (bis zu vier Jahren) erforderlich machen oder die
                        Daten zum Schutz der Rechte einer anderen natürlichen oder juristischen Person gebraucht werden,
                        löschen wir (den Teil) Ihre(r) Daten erst nach Ablauf dieser Fristen. Bis zum Ablauf dieser
                        Fristen beschränken wir die Verarbeitung dieser Daten jedoch auf diese Zwecke (Erfüllung der
                        Aufbewahrungspflichten).
                    </p>
                </article>


                <article className={'mt-18'}>
                    <Headline title={'Hosting'}></Headline>
                    <p>
                        Wir bedienen uns zum Vorhalten unserer Internetseiten eines Anbieters, auf dessen Server unsere
                        Internetseiten gespeichert und für den Abruf im Internet verfügbar gemacht werden (Hosting).
                        Hierbei können von dem Anbieter all diejenigen über den von Ihnen genutzten Browser übertragenen
                        Daten verarbeitet werden, die bei der Nutzung unserer Internetseiten anfallen. Hierzu gehören
                        insbesondere Ihre IP-Adresse, die der Anbieter benötigt, um unser Online-Angebot an den von
                        Ihnen genutzten Browser ausliefern zu können sowie sämtliche von Ihnen über unsere Internetseite
                        getätigten Eingaben. Daneben kann der von uns genutzte Anbieter&nbsp;&nbsp;
                    </p>
                    <ul>
                        <li>das Datum und die Uhrzeit des Zugriffs auf unsere Internetseite</li>
                        <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
                        <li>Zugriffsstatus (HTTP-Status)</li>
                        <li>die übertragene Datenmenge</li>
                        <li>der Internet-Service-Provider des zugreifenden Systems</li>
                        <li>der von Ihnen verwendete Browsertyp und dessen Version</li>
                        <li>das von Ihnen verwendete Betriebssystem</li>
                        <li>die Internetseite, von welcher Sie gegebenenfalls auf unsere Internetseite gelangt sind</li>
                        <li>die Seiten bzw. Unterseiten, welche Sie auf unserer Internetseite besuchen.</li>
                    </ul>
                    <p>
                        erheben. Die vorgenannten Daten werden als Logfiles auf den Servern unseres Anbieters
                        gespeichert. Dies ist erforderlich, um die Stabilität und Sicherheit des Betriebs unserer
                        Internetseite zu gewährleisten.
                    </p>
                    <p className="invisible">&nbsp;</p>
                    <p><strong>Betroffene Daten:</strong></p>

                    <ul>
                        <li>Inhaltsdaten (bspw. Posts, Fotos, Videos)</li>
                        <li>Nutzungsdaten (bspw. Zugriffszeiten, angeklickte Webseiten)</li>
                        <li>Kommunikationsdaten (bspw. Informationen über das genutzte Gerät, IP-Adresse)</li>
                    </ul>
                    <p>
                        <strong>Betroffene Personen: </strong>Nutzer unserer Internetpräsenz
                    </p>
                    <p>
                        <strong>Verarbeitungszweck: </strong>Ausspielen unserer Internetseiten, Gewährleistung des
                        Betriebs unserer Internetseiten
                    </p>
                    <p>
                        <strong>Rechtsgrundlage:</strong> Berechtigtes Interesse, Art. 6 Abs. 1 lit. f DSGVO
                    </p>
                    <p>
                        <strong>Von uns beauftragte(r) Webhoster:</strong>
                    </p>
                    <p>
                        netcup GmbH<br/>
                        Daimlerstraße 25<br/>
                        D-76185 Karlsruhe<br/>
                    </p>
                    <p>
                        <a href="mailto:mail@netcup.de" target={'_blank'}>mail@netcup.de</a><br/>
                        <a href={'https://www.netcup.de'} target={'_blank'}>www.netcup.de</a>
                    </p>
                </article>


                <article className={'mt-18'}>
                    <Headline title={'Sicherheitsmaßnahmen'}></Headline>
                    <p>
                        Wir treffen im Übrigen technische und organisatorische Sicherheitsmaßnahmen nach dem Stand der
                        Technik, um die Vorschriften der Datenschutzgesetze einzuhalten und Ihre Daten gegen zufällige
                        oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen
                        den unbefugten Zugriff Dritter zu schützen.
                    </p>
                    <h2>Aktualität und Änderung dieser Datenschutzerklärung</h2>
                    <p>
                        Diese Datenschutzerklärung ist aktuell gültig und hat den Stand
                        September 2022. Aufgrund geänderter gesetzlicher bzw. behördlicher Vorgaben kann es notwendig
                        werden, diese Datenschutzerklärung anzupassen.
                    </p>
                </article>
            </div>
        </Layout>
    )
};

export const Head = () => {
    return (<SEO title={'Datenschutz'}></SEO>)
}

